.MuiStack-root {
    & .MuiFormControl-root {
        & .MuiInputBase-root {
            // width: 70%;
            & .MuiInputBase-input {
                background-color: rgba(0, 0, 0, 0.06);
                // background: linear-gradient(0deg, rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), #6C6D71;
                padding: 9.5px 8px;
                //     border-radius: 10px;
                //     width: 8vw;
                //     height: 8vh;
            }

            & .MuiInputBase-input:hover {
                border-color: "none";
                outline: "none";
            }
        }
    }
}
