.MuiBox-root {
    .MuiTableHead-root {
        .MuiTableRow-root {
            & .MuiTableCell-root {
                padding: 0px;
                padding-left: 6px;
                background: linear-gradient(
                        0deg,
                        rgba(255, 255, 255, 0.95),
                        rgba(255, 255, 255, 0.95)
                    ),
                    #6c6d71;
            }
        }
    }
    .MuiTableContainer-root {
        .MuiTableBody-root {
            & .MuiTableRow-root {
                // box-shadow: none;
                & .MuiTableCell-root {
                    padding: 10px 6px;
                    // border-bottom: 0px;
                }
            }
        }
    }
}
