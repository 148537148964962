.MuiBox-root {
    .lead-tabs {
        .MuiButtonBase-root {
            text-transform: none;

            &.Mui-selected {
                color: #84bd00;
            }
        }
        .MuiChip-root {
            color: #84bd00;
            background-color: rgba(137, 129, 61, 0.15);
        }
    }

    .MuiBox-root .MuiTypography-root {
        .MuiBox-root .MuiFormControl-root {
            //    border: 1px solid blue;
            width: 100%;

            // .MuiFormLabel-root-MuiInputLabel-root {
            .MuiInputBase-root-MuiFilledInput-root {
                border-bottom: 0px;
                border-radius: 40px;

                &:hover {
                    border-bottom: 0px;
                    border-radius: 40px;
                }

                // }
            }
        }
    }
}
.filter-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    .sub-container .lbl {
        color: #919295;
        font-weight: 400;
        font-size: 15px;
        line-height: 24px;
        margin-top: 16px;
        gap: 10px;
    }
}
.MuiBox-root {
    .MuiTypography-root .lead-table-container {
    }

    .MuiTableContainer-root {
        margin: 10px;

        .MuiTableHead-root {
            background-color: #76777b26;
            border-radius: 8px;

            .MuiTableRow-root {
                .MuiTableCell-root {
                    text-align: left;
                }
            }
        }

        .MuiTableBody-root {
            .MuiTableRow-root {
                border-radius: 10px;
                box-shadow: 0px 2px rgba(0, 0, 0, 0.1);

                .MuiTableCell-root {
                }
            }
        }
    }
}
