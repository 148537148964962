.MuiListItemText-root.sub-list-item {
    display: flex;
    align-items: center;
    gap: 15px;
    word-break: break-all;
    justify-content: flex-start;

    .MuiListItemText-primary {
        flex: 1;
    }

    .MuiListItemText-secondary {
        margin-left: 10px;
        flex: 1;
    }
}

.list-divider {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 2rem;
}
