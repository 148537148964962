label {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* Gray/G40 */
    color: #919295;
}

.MuiBox-root {
    &.MuiPaper-root {
        overflow: auto;
    }
}
