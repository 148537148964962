.MuiBox-root {
    .css-pjjt8i-MuiToolbar-root {
        // .css-2pbtu6-MuiTypography-root {
        //     font-size: 2.5rem;
        //     line-height: 42px;
        //     color: "#464B55";
        //     font-weight: 600;
        // }
    }

    .MuiBox-root .MuiTableContainer-root .MuiTableBody-root .MuiTableRow-root {
        // border-radius: 0px;
        // box-shadow: none;
        padding: 10px 0px;
        background: #ffffff;
        box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
    }
}
