@font-face {
    font-family: "DB Helvethaica X 35 Thin";
    src:
        local("DB Helvethaica X 35 Thin"),
        url("./font/DB Helvethaica X 35 Thin.ttf") format("truetype");
}
@font-face {
    font-family: "DB Helvethaica X 55 Regular";
    src:
        local("DB Helvethaica X 55 Regular"),
        url("./font/DB Helvethaica X 55 Regular.ttf") format("truetype");
}
@font-face {
    font-family: "DB Helvethaica X 75 Bd";
    src:
        local("DB Helvethaica X 75 Bd"),
        url("./font/DB Helvethaica X 75 Bd.ttf") format("truetype");
}
@font-face {
    font-family: "DB Helvethaica X 65 Med";
    src:
        local("DB Helvethaica X 65 Med"),
        url("./font/DB Helvethaica X 65 Med.ttf") format("truetype");
}
body {
    margin: 0;
    font-family: "DB Helvethaica X 55 Regular";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #47474a;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        "DB Helvethaica X", sans-serif, monospace;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

.div::-webkit-scrollbar {
    width: 4px; /* width of the entire scrollbar */
}

.div::-webkit-scrollbar-track {
    background: white; /* color of the tracking area */
}

.div::-webkit-scrollbar-thumb {
    background-color: lightgray; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    //border: 3px solid orange;  /* creates padding around scroll thumb */
}

.MuiDrawer-root .MuiChip-root {
    display: none !important;
}
