.image-box {
    width: 150px;
    height: 150px;
    position: relative;
    bottom: 70px;
    border-radius: 20px;
    margin-left: 15px;

    img {
        border-radius: 12px;
        border: 0.5px solid #eaebeb;
        filter: drop-shadow(0px 4px 80px rgba(0, 0, 0, 0.08));
    }
}

.pad {
    padding: 15px;
}

.side-image-upload {
    background: #0000006e;
    width: initial;
    border: 1px solid white;
    position: relative;
    padding: 7px;
    bottom: 7rem;
    border-radius: 10px;
    right: 27px;
    color: white;
}

.zero-visits {
    color: #84bd00;
    font-size: x-large;
}

.small-image-icon {
    position: absolute;
    background: #0000006e;
    bottom: -5px;
    right: -5px;
    border-radius: 10px;
    width: 25px;
    height: 25px;
    text-align: center;
}

.MuiCardContent-root {
    display: flex;
    justify-content: space-between;
}
