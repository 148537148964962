.main-heading {
    font-weight: 550;
    font-size: 50px;
    line-height: 60px;
    text-align: Left;
    vertical-align: Top;
    padding: 1rem 0rem;
    color: #47474a;
    font-family: "DB Helvethaica X 65 Med";
}

.MuiBox-root {
    .MuiPaper-root .MuiPaper-elevation .MuiCard-root {
        overflow: auto;
    }
}
