.maps-container {
    position: relative;
    .map-container {
        height: 400px;
        width: 100%;
    }
}

.map-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 35px;
    font-weight: 550;
    color: white;
    background: #d3d3d3;
}

.mapboxgl-ctrl-logo {
    display: none !important;
}
