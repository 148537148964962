.MuiBox-root {
    .css-pjjt8i-MuiToolbar-root {
        .css-2pbtu6-MuiTypography-root {
            font-size: 2.5rem;
            line-height: 42px;
            color: "#464B55";
            font-weight: 600;
        }
    }
}

.table-head-row {
    width: 1048px;
    height: 48px;
    background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.95),
            rgba(255, 255, 255, 0.95)
        ),
        #6c6d71;
    border-radius: 8px;
}

.MuiPagination-ul {
    float: right;
}

.page-display-count {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #919295;
    margin: 6px 0px 0px 3px;
}

.MuiPagination-root {
    margin-left: auto;
}

li .Mui-selected {
    background-color: #84bd00 !important;
    color: #ffffff !important;
}

li .MuiPaginationItem-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;

    width: 36px;
    height: 36px;
    border-radius: 8px;
}

.table-controll .MuiSelect-select {
    width: 47px;
    font-size: 22px;
    color: #47474a;
    padding: 0px 14px !important;
}
.table-controll .MuiOutlinedInput-root {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    gap: 4px;
    height: 36px;
    border-radius: 8px;
}

.table-controll .MuiInputLabel-root {
    margin: 6px 10px 0px 25px;
    color: #919295;
}
